<template>
	<div class="chat-messages">
		<div class="chat-messages__header">
			<div class="chat-messages__header_img">
				<tk-image
					width="60"
					height="60"
					:src="chat.room.recipient.avatar || '/images/default-avatar.svg'"
					:alt="chat.room.recipient.name"
				/>
			</div>

			<div class="chat-messages__header_text">
				<div class="username">{{ chat.room.recipient.name }}</div>
        <div v-if="!chat.room.recipient.is_online" class="last-visit">
          был(-а) <span>{{ chat.room.recipient.lastTimeOnline }}</span>
        </div>
        <div v-else class="last-visit">
          Онлайн
        </div>
			</div>

			<div v-if="!chat.room.isBlockedByUser" class="chat-messages__header_block" @click="chat.blockRoomById()">
				<div class="block-img">
					<img
						src="~@/assets/images/svg/disabled.svg"
						alt="Иконка Заблокировать"
					/>
				</div>
				<div class="block-text">Заблокировать</div>
			</div>
      <div v-else class="chat-messages__header_block" @click="chat.unblockRoomByUserId()">
        <div class="block-img">
          <img
            src="~@/assets/images/svg/disabled.svg"
            alt="Иконка Разблокировать"
          />
        </div>
        <div class="block-text">Разблокировать</div>
      </div>

			<!-- <a href="tel:71234567890" class="chat-messages__header_call">
				<img src="~@/assets/images/svg/phone.svg" alt="Позвонить" />
			</a> -->
		</div>

		<div ref="messagesArea" @scroll.passive="handleScroll($event)" class="messages-area">
			<div
				v-for="message in chat.messages"
				:key="message.id"
				:class="[
					'message',
					message.isUserselfMessage
						? 'message__user--first'
						: 'message__user--second'
				]"
        @mouseenter="chat.readMessage(message)"
			>
				<div v-html="renderMsg(message.text)" />
				<div class="time">
					<span>{{ message.datetime }}</span>
					 <div v-if="message.is_read && message.isUserselfMessage" class="img">
              <img src="@/assets/images/svg/message-sended.svg" alt="отправленное сообщение"  decoding="async" />
            </div>
				</div>
			</div>

			<div v-if="!this.chat.room.isBlocked" class="write-message">
				<div class="write-message__input-wrapper">
					<tk-input
						v-model="text"
						class="chat-message-input"
						placeholder="Введите сообщение"
						:is-clear-button="false"
            @focus="chat.readMessage()"
            @keydown.enter="sendMsg"
					/>
				</div>
				<button
					class="btn write-message__submit"
					:disabled="!text.length"
					@click="sendMsg"
				>
					<tk-svg type="message-send" />
				</button>
			</div>
      <div v-else class="write-message">
        <h2>{{ chat.room.blockingReason }}</h2>
      </div>
      <div class="write-message__background"></div>
		</div>
	</div>
</template>

<script>
import TkSvg from "@/components/global/tkSvg";
import ChatEventsEnum from "@/chat/chatEventsEnum";
import { renderMsg } from "@/utils/bbcodeHelpers";
import router from "@/router";

export default {
  components: { TkSvg },
  inject: ['chat'],
  data() {
    return {
      text: '',
      scrollBottom: 0,
      prevScrollHeight: 0,
    }
  },
  computed: {
    messagesArea() {
      return this.$refs.messagesArea
    },
  },
  methods: {
    handleScroll(event) {
      this.scrollBottom = this.messagesArea.scrollHeight - this.messagesArea.scrollTop
      if (this.messagesArea.scrollTop < 1) {
        // +1 убирает проблемы с авто-прокруткой до самого верха
        this.messagesArea.scrollTop = this.messagesArea.scrollHeight - this.scrollBottom + 1
        this.chat.loadPreviousMessages()
      }
    },
    renderMsg,
    sendMsg() {
      if (!this.text.trim()) {
        return
      }

      this.chat.sendMessage(this.text)
      this.text = ''
    },
    smartRedirect(event) {
      if (event.target?.id === 'teremok-chat-link') {
        event.preventDefault()
        const link = event.target.href
        if (link.startsWith(window.location.origin)) {
          router.push(link.substr(window.location.origin.length))
        }
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.smartRedirect);
  },
  updated() {
    // если изменилось кол-во отображаемых сообщений (размер контейнера для них)
    if (this.prevScrollHeight !== this.messagesArea.scrollHeight) {
      this.prevScrollHeight = this.messagesArea.scrollHeight
      // если получено новое сообщение и оно:
      // 1. Либо написано нами
      // 2. Либо написано собеседником и окно чата прокручено вниз
      // прокручиваем окно чата вниз. прокрутка элемента в конец может
      // возвращать различные значения с разбегом в ~10 единиц
      if ((this.scrollBottom - this.messagesArea.clientHeight < 15) ||
        (this.chat.lastEvent?.type === ChatEventsEnum.NEW_MESSAGE &&
          this.chat.lastEvent?.obj.isUserselfMessage))
      {
        this.messagesArea.scrollTop = this.messagesArea.scrollHeight
        this.scrollBottom = 0
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('click', this.smartRedirect)
  }
}
</script>

<style lang="sass">
.chat-list__chats::-webkit-scrollbar,
.messages-area::-webkit-scrollbar
  width: 6px
.chat-list__chats::-webkit-scrollbar-track,
.messages-area::-webkit-scrollbar-track
  background-color: transparent
  border-radius: 2px
.chat-list__chats::-webkit-scrollbar-thumb,
.messages-area::-webkit-scrollbar-thumb
  background: #1AC386
  border-radius: 2px
  border: 0.3px solid #1AC386
/* стили скроллбаров*/

.chat-messages
  max-height: 810px
  width: 100%
  position: relative
  overflow: hidden
  // .backbutton
  //   visibility: hidden
  &__header
    display: flex
    align-items: center
    height: 83px
    padding: 11px 25px 10px 25px
    background: #FFFFFF
    border: 1px solid #E2E2E2
    border-left: none
    box-sizing: border-box
    border-radius: 0 20px 0px 0px
    &_img
      margin-right: 10px
      width: 60px
      height: 60px
      position: relative
      border-radius: 50%
      flex-shrink: 0
      img
        object-fit: cover
        border-radius: 50%
        position: absolute
        width: 100%
        height: 100%
    &_text
      .last-visit
        font-size: 12px
        line-height: 12px
        color: #CBCBCB
        margin-top: 5px
      .username
        font-weight: 600
        font-size: 14px
        line-height: 14px
        color: #3B3F47
    &_block
      display: flex
      align-items: center
      margin-left: auto
      margin-right: 25px
      .block-img
        margin-right: 5px
      .block-text
        font-size: 12px
        line-height: 12px
        color: #E93030
      &:hover
        cursor: pointer
        .block-text
          text-decoration: underline
    &_call
      transition: 0.2s linear
      &:hover
        cursor: pointer
        transition: 0.2s linear
        transform: scale(1.1) rotate(60deg)

  .messages-area
    overflow-y: auto
    // position: relative
    background: #F5F5F5
    padding-top: 20px
    padding-bottom: 86px
    // padding-bottom: 18px
    padding-left: 28px
    padding-right: 28px
    height: 100%
    max-height: 686px
    border-radius: 0px 0px 20px 0
    display: flex
    flex-grow: 1
    flex-direction: column
    // justify-content: flex-end
    .message
      word-break: break-all
      padding: 17px 17px 5px 15px
      width: 80%
      margin-bottom: 20px
      font-size: 16px
      line-height: 18px
      position: relative
      word-break: break-all
      img
        border-radius: 10px
        margin-top: 5px
        max-width: 100%
      .time
        display: flex
        opacity: 0.2
        font-size: 11px
        line-height: 13px
        margin-top: 22px
        color: #000000
        span
          margin-right: 3px
        .img
          position: relative
          &:before
            // content: url('~@/assets/images/svg/message-delivered.svg')
            position: absolute
            right: -3px
            bottom: -2px
            opacity: 1
      &__user--first
        position: relative
        align-self: flex-end
        background: #ffffff
        border-radius: 10px 10px 0px 10px
        color: #000000
        a
          color: #1AC386
          text-decoration: underline
        &:before
          content: ''
          position: absolute
          width: 13px
          height: 18px
          bottom: 0
          right: -12px
          background: radial-gradient(180% 180% at right -65% top -65%, transparent 99%, #ffffff )
        .time
          justify-content: flex-end
      &__user--second
        position: relative
        background: #1AC386
        align-self: flex-start
        border-radius: 10px 10px 10px 0px
        color: #FFFFFF
        a
          color: white
          text-decoration: underline
        &:before
          content: ''
          position: absolute
          width: 13px
          height: 18px
          bottom: 0
          left: -12px
          background: radial-gradient(180% 180% at left -65% top -65%, transparent 99%, #1AC386 )
        .time
          justify-content: flex-start
  .write-message
    position: absolute
    box-sizing: content-box
    z-index: 10
    bottom: 18px
    width: 90%
    display: flex
    justify-content: space-between
    align-items: center
    h2
      margin: 0
    &__input-wrapper
      width: 100%
      margin-right: 25px
    &__submit
      display: flex
      align-items: center
      justify-content: center
      width: 60px
      min-width: 60px
      height: 60px
      border-radius: 12px
      border: none
      background-color: $green
      box-shadow: 0px 5px 15px rgba(95, 83, 80, 0.3)
  .write-message__background
    position: absolute
    bottom: 0px
    width: 96%
    left: 0
    height: 80px
    background: #F5F5F5

@media screen and (max-width: 1180px)
  .chat-messages
    // width: 396px
    .messages-area
      .message
        font-size: 15px
        .time
          margin-top: 10px

@media screen and (max-width: 1024px)
  .chat-messages
    // width: 370px
    .messages-area
      .write-message
        width: 88%
      .message
        font-size: 14px
        .time
          margin-top: 5px

@media screen and (max-width: 1000px)
  .chat-messages
    &__header
      border-left: 1px solid #E2E2E2
      border-radius: 20px 20px 0px 0px
      padding: 15px
      &_block
        margin-right: 0
    .messages-area
      border-radius: 0px 0px 20px 20px

@media screen and (max-width: 479px)
  .chat-messages
    max-height: 640px
    border-radius: 10px
    &__header
      border-left: 1px solid #E2E2E2
      border-radius: 20px 20px 0px 0px
      padding: 15px
      .username
        font-size: 12px
    .messages-area
      padding-bottom: 160px
      .write-message
        width: 80%
        &__submit
          width: 40px
          min-width: 40px
          height: 40px
        h2
          font-size: 16px

</style>
