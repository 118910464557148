<template>
	<section class="chat-section">
		<!-- на моб.адаптиве переход в сообщения конкретного чата через тогл класса контейнеру chats || messages -->
		<div
			class="container-part messages"
			v-if="chat.roomsCount"
			:class="{ chats: !chat.isRoomSelected, messages: chat.isRoomSelected }"
		>
			<div class="main-content">
				<div class="main-content__chat">
					<template v-if="isMobile">
						<tk-button
							v-if="chat.isRoomSelected"
							@click="chat.exitRoom()"
							class="main-content__btn-list"
							kind="back-button"
						>
							<template #icon-before>
								<tk-svg type="arrow-top" />
							</template>
							К списку чатов
						</tk-button>

						<Chats v-if="!chat.isRoomSelected" />
						<Messages v-else />
					</template>
					<template v-else>
						<Chats />

						<Messages v-if="chat.isRoomSelected" />
						<div class="main-content__empty-chat" v-else>Выберите чат</div>
					</template>
				</div>
			</div>
		</div>

		<div v-else class="chat-section__empty chat-empty">
			<h2 class="chat-empty__title">Сообщения</h2>

			<p class="chat-empty__content">
				<span class="chat-empty__content--article">
					У вас на данный момент нет еще созданных чатов.
				</span>

        Чтобы начать новый диалог необходимо в объявлении нажать кнопку «Связаться с хозяином».
        Уважайте друг друга и умейте договариваться.
			</p>
		</div>
	</section>
</template>

<script>
import { inject, onMounted, ref } from "vue";

import Chats from './components/chats'
import Messages from './components/messages'

export default {
	name: 'Chat',
	components: {
		Chats,
		Messages
	},
	setup() {
		const isMobile = ref(false)
    const chat = inject('chat')

		onMounted(() => {
			onResize()
			window.addEventListener('resize', onResize, { passive: true })
		})

		const onResize = () => {
			isMobile.value = window.innerWidth < 1000
		}

		return {
			isMobile,
      chat,
		}
	}
}
</script>

<style lang="sass">
.chat-section
  background-color: #ffffff

  .chat-empty

    &__title
      margin-top: 0
      margin-bottom: 15px

      font-weight: bold
      font-size: 24px
      line-height: 24px
      color: #3B3F47

    &__content
      margin: 0
      max-width: 390px

      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      color: #CBCBCB

      &--article
        display: block
        margin-bottom: 15px

  .main-content
    position: relative
    display: flex
    justify-content: space-between
    width: 100%

    &__empty-chat
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      padding: 20px
      background: #F5F5F5
      border-radius: 0 20px 20px 0

    &__chat
      display: flex
      position: relative
      width: 100%
      height: 768px

    &__btn-list
      position: absolute
      top: -25px
      max-width: 135px
      z-index: 99

// 540
@media screen and (max-width: 800px)
  .chat-section
    padding-bottom: 30px
    padding-top: 20px
  .container.chats
    width: 100%
    .main-content
      width: 100%
      .main-content__chat
        width: 100%
        .chat-list
          width: 100%
          border: none
          .house-rules
            display: block
          &__chats
            overflow-y: inherit
            .chats-item
              position: relative
              background: #FFFFFF
              border: 0.5px solid #E2E2E2
              box-sizing: border-box
              border-radius: 10px
              flex-direction: column
              padding: 0
              margin-bottom: 20px
              margin-right: 13px
              margin-left: 13px
              .userpic
                margin-top: 15px
                margin-left: 15px
                margin-bottom: 20px
              .text
                border: none
                padding-right: 0
                .online-status
                  display: block
                  position: absolute
                  top: 30px
                  left: 90px
                .username
                  position: absolute
                  top: 46px
                  left: 90px
                .message-preview
                  font-size: 14px
                  line-height: 16px
                  color: #3B3F47
                  padding-left: 15px
                  padding-right: 15px
                  padding-bottom: 20px
                  margin: 0
                .booking-status
                  font-weight: 600
                  font-size: 14px
                  line-height: 14px
                  text-align: left
                  padding-top: 10px
                  padding-left: 15px
                  padding-right: 15px
                  padding-bottom: 10px
                  border-top: 0.5px solid #E2E2E2
                  .block
                    display: flex
              &.notification
                &:before
                  top: 15px
                  right: 15px
          &__header
            display: none
        .chat-messages
          display: none

  .container.messages
    width: 100%
    padding: 0
    .main-content
      width: 100%
      .main-content__chat
        width: 100%
        flex-wrap: wrap
        .chat-list
          width: 100%
          height: auto
          border: none
          &__chats
            overflow-y: inherit
            .chats-item
              display: none
              &.notification
                &:before
                  top: 15px
                  right: 15px
            .chats-item.active
              display: flex
              position: relative
              background: #FFFFFF
              border: 0.5px solid #E2E2E2
              box-sizing: border-box
              border-radius: 10px
              flex-direction: column
              padding: 0
              z-index: 2
              .userpic
                margin-top: 15px
                margin-left: 15px
                margin-bottom: 20px
              .text
                border: none
                padding-bottom: 11px
                padding-right:
                .call
                  display: block
                .online-status
                  display: block
                  position: absolute
                  top: 30px
                  left: 90px
                .username
                  position: absolute
                  top: 46px
                  left: 90px
                .message-preview
                  display: none
                .booking-status
                  padding-left: 14px
                  padding-right: 14px
                  padding-top: 10px
                  font-weight: 600
                  font-size: 14px
                  line-height: 14px
                  text-align: center
                  border-top: 0.5px solid #E2E2E2
                  .block
                    display: flex
          &__header
            display: none
        .chat-messages
          width: 100%
          &__header
            display: none
          .messages-area
            width: 100%
            margin-top: -10px
            border-radius: 0px 0px 20px 20px
            height: 510px
            .message
              font-size: 14px
              line-height: 18px
              padding-top: 16px
              padding-left: 16px
              padding-right: 16px
              .time
                margin-top: 4px
            .write-message
              input
                height: 50px
</style>
